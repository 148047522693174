import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
  } from '@ionic/react';
  
  import { useLocation } from 'react-router-dom';
  import { archiveOutline, archiveSharp, bookmarkOutline, camera, cameraOutline, carOutline, cart, cartOutline, diamond, diamondOutline, flash, flashOutline, heart, heartOutline, heartSharp, home, homeOutline, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, people, peopleOutline, person, personOutline, ribbon, ribbonOutline, thumbsUp, thumbsUpOutline, trailSign, trailSignOutline, trashOutline, trashSharp, trophy, trophyOutline, warningOutline, warningSharp, wine, wineOutline, woman, womanOutline } from 'ionicons/icons';
  import './Menu.css';
  
  interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
  }
  
  const appPages: AppPage[] = [
    {
      title: 'Acceuil',
      url: '/home',
      iosIcon: homeOutline,
      mdIcon: home
    },
    // {
    //   title: 'Diamond Help',
    //   url: '/help',
    //   iosIcon: heartOutline,
    //   mdIcon: heart
    // },
    // {
    //   title: 'Model Managment',
    //   url: '/model',
    //   iosIcon: peopleOutline,
    //   mdIcon: people
    // },
    {
      title: 'Vote Miss',
      url: '/votemiss/vote',
      iosIcon: thumbsUpOutline,
      mdIcon: thumbsUp
    },
    {
      title: 'Vote Award',
      url: '/voteaward/vote',
      iosIcon: ribbonOutline,
      mdIcon: ribbon
    },
    // {
    //   title: 'Catalogue',
    //   url: '/page/Archived',
    //   iosIcon: cartOutline,
    //   mdIcon: cart
    // },
    // {
    //   title: 'photo Gallery',
    //   url: '/page/Trash',
    //   iosIcon: cameraOutline,
    //   mdIcon: camera
    // },
    {
      title: 'KapyGenius',
      url: '/kapygenius',
      iosIcon: flashOutline,
      mdIcon: flash
    },
    {
      title: 'Compte',
      url: '/account',
      iosIcon: personOutline,
      mdIcon: person
    }
  ];
  
  const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  
  const Menu: React.FC = () => {
    const location = useLocation();
  
    return (
      <IonMenu contentId="main" type="overlay">
        <IonContent>
          <IonList id="inbox-list">
            <IonListHeader>
              <IonLabel><IonIcon slot="start" ios={diamondOutline} md={diamond} /> MDC </IonLabel>
              </IonListHeader>
            <IonNote>hi, hope you're fine!</IonNote>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
  
          {/* <IonList id="labels-list">
            <IonListHeader>Labels</IonListHeader>
            {labels.map((label, index) => (
              <IonItem lines="none" key={index}>
                <IonIcon slot="start" icon={bookmarkOutline} />
                <IonLabel>{label}</IonLabel>
              </IonItem>
            ))}
          </IonList> */}
        </IonContent>
      </IonMenu>
    );
  };
  
  export default Menu;
  