import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'

function Header(props: any) {
    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons>
                    <IonMenuButton />
                    <IonTitle>{props?.title} </IonTitle>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}

export default Header