import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react'
import { arrowBack, arrowBackOutline, chevronBackOutline, chevronDownOutline } from 'ionicons/icons'
import React from 'react'

function ModalHeader(props:any) {
  return (
    <IonHeader>
        <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => props?.dismiss()}>
                    <IonIcon ios={chevronBackOutline } md={arrowBack}/>
                    Back
                </IonButton>
              </IonButtons>
          <IonTitle> {props?.title}</IonTitle>
          <IonButtons slot="end">
                <IonButton strong={true} onClick={() => props?.dismiss()}>
                  <IonIcon icon={chevronDownOutline }/>
                </IonButton>
              </IonButtons>
        </IonToolbar>
      </IonHeader>
  )
}

export default ModalHeader