import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNav,
  IonNavLink,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  cameraOutline,
  cartOutline,
  chatboxEllipsesOutline,
  colorFillSharp,
  diamondOutline,
  eyeOutline,
  heart,
  heartOutline,
  megaphone,
  megaphoneOutline,
  peopleOutline,
  ribbonOutline,
  sparklesOutline,
  thumbsUpOutline,
  wineOutline,
  womanOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { BsStars } from "react-icons/bs";
import { GiCutDiamond, GiMoneyStack } from "react-icons/gi";
import { arrowForwardOutline } from "ionicons/icons";
import { useGetDataRequest } from "../../data/request";
import dateFormat from "dateformat";
import ContactCandidates from "../contact/ContactCandidates";
import CandidateDetails from "../detail/CandidateDetails";
import ReservationGala from "../contact/ReservationGala";
import VoteMiss from "../services/VoteMiss";
import VoteAward from "../services/VoteAward";
import DiamondHelp from "./DiamondHelp";
import ModelManag from "./ModelManag";
import PhotoGallery from "./PhotoGallery";
import Catalogue from "./Catalogue";
import AwardsDetails from "../detail/AwardsDetails";
import SponsorsDetails from "../detail/SponsorsDetails";
import ContactSponsors from "../contact/ContactSponsors";
import ContactMDC from "../contact/ContactMDC";
import ContactModel from "../contact/ContactModel";
import ArticleDetails from "../detail/ArticleDetails";
import Login from "../../components/Login";

const services = [
  {
    nom: "Miss Vote",
    icon: thumbsUpOutline,
    color: "primary",
    link: "/votemiss/vote",
    component: VoteMiss,
  },
  {
    nom: "Award Vote",
    icon: ribbonOutline,
    color: "tertiary",
    link: "/voteaward/vote",
    component: VoteAward,
  },
  // {
  //     nom: "Diamond Help",
  //     icon: heartOutline,
  //     color: "primary",
  //     link: "/diamondhelp",
  //     component: DiamondHelp
  // },
  // {
  //     nom: "Model Manag.",
  //     icon: peopleOutline,
  //     color: "tertiary",
  //     link: "/modelmanag",
  //     component: ModelManag
  // },
  // {
  //     nom: "photo Gallery",
  //     icon: cameraOutline,
  //     color: "primary",
  //     link: "/photogallery",
  //     component: PhotoGallery
  // },
  // {
  //     nom: "Catalogue",
  //     icon: cartOutline,
  //     color: "tertiary",
  //     link: "/catalogue",
  //     component: Catalogue
  // }
];

const contacts = [
  {
    nom: "Reservation Table Gala",
    icon: wineOutline,
    color: "tertiary",
    link: "/contact/reservationgala",
    id: "contactlistreservationgala1",
    component: <ReservationGala trigger="contactlistreservationgala1" />,
  },
  {
    nom: "Devenir Sponsor/ Partenaire",
    icon: megaphoneOutline,
    color: "primary",
    link: "/contact/sponsor",
    id: "contactlistsponsor2",
    component: <ContactSponsors trigger="contactlistsponsor2" />,
  },
  {
    nom: "Contact MDC Team",
    icon: chatboxEllipsesOutline,
    color: "primary",
    link: "/contact/mdc",
    id: "contactlistmdc3",
    component: <ContactMDC trigger="contactlistmdc3" />,
  },
  {
    nom: "Enregistrement Miss",
    icon: diamondOutline,
    color: "tertiary",
    link: "/contact/candidates",
    id: "contactlistcandidates4",
    component: <ContactCandidates trigger="contactlistcandidates4" />,
  },
  {
    nom: "Devenir createur Fashion",
    icon: sparklesOutline,
    color: "tertiary",
    link: "/contact/mdc",
    id: "contactlistcreateur5",
    component: (
      <ContactSponsors trigger="contactlistcreateur5" categorie="createur" />
    ),
  },
  {
    nom: "Devenir Top Model",
    icon: womanOutline,
    color: "primary",
    link: "/contact/model",
    id: "contactlisttopmodel6",
    component: <ContactModel trigger="contactlisttopmodel6" />,
  },
];
const AcceuilHome: React.FC = () => {
  const [content, setContent] = useState("home");
  const images = [
    "misstogether.jpeg",
    "finalmiss.jpeg",
    "miss_together.jpeg",
    "miss_swag.jpeg",
  ];

  const [votesparticipantes]: any[] = useGetDataRequest(
    "voteparticipantes",
    "/voteparticipantes"
  );
  const [awardsparticipants]: any[] = useGetDataRequest(
    "awardcandidats",
    "/awardcandidats"
  );
  const [sponsors]: any[] = useGetDataRequest("sponsors", "/sponsors");
  const [actualites]: any[] = useGetDataRequest(
    "articles",
    "/articles?_sort=createdAt%3Adesc"
  );
  const [homeData]: any[] = useGetDataRequest("mdcapp", "/mdcapp");

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    navigation: true,
    pagination: true,
  };
  const slideOpts2 = {
    slidesPerView: 2,
    initialSlide: 0,
    speed: 400,
    //autoplay:true
  };
  const slideOpts3 = {
    slidesPerView: 4,
    initialSlide: 0,
    speed: 400,
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons>
            <IonMenuButton />
            <IonTitle>MDC Acceuil</IonTitle>
          </IonButtons>
        </IonToolbar>
        <IonSegment
          value={content}
          onIonChange={(e) => {
            setContent(e.detail.value || "home");
          }}
        >
          <IonSegmentButton value="home" layout="icon-start">
            <IonLabel>Home</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="actualite" layout="icon-start">
            <IonLabel>Actualite</IonLabel>
          </IonSegmentButton>

          {/* <IonSegmentButton value='contact' layout='icon-start'>
                        <IonLabel>Contact</IonLabel>
                    </IonSegmentButton> */}
        </IonSegment>
      </IonHeader>
      <IonContent
        onScroll={(e) => {
          console.log(e);
        }}
      >
        {content == "actualite" && (
          <>
            {actualites &&
              actualites.map((actualite: any) => (
                <IonCard key={actualite.id} id={actualite.id}>
                  <IonItem>
                    <IonAvatar slot="start">
                      <img src="/assets/images/mdc.jpeg" />
                    </IonAvatar>
                    <IonLabel>
                      <h2>
                        MDC Team{" "}
                        <IonText color="secondary">
                          <GiCutDiamond />
                        </IonText>
                      </h2>
                      <p>{dateFormat(actualite.createdAt)}</p>
                    </IonLabel>
                  </IonItem>
                  <img src={actualite?.image?.url} />
                  <IonCardHeader>
                    <IonCardSubtitle color="tertiary">
                      Actualité
                    </IonCardSubtitle>
                    <IonCardTitle>{actualite.titre}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {actualite.test.slice(0, 100)}...
                  </IonCardContent>
                  <IonRow>
                    <IonCol>
                      <IonButton fill="clear">
                        <IonIcon ios={eyeOutline} />
                        <div>&nbsp;open</div>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <ArticleDetails article={actualite} trigger={actualite.id} />
                </IonCard>
              ))}
          </>
        )}

        {content == "home" && (
          <>
            <IonText class="ion-text-center">
              <h4>Miss Diamond Africa</h4>
              <h6 style={{ marginTop: "-5px" }}>
                <IonText color="secondary">
                  <GiCutDiamond />
                </IonText>{" "}
                Le Diamant c'est toi Miss{" "}
                <IonText color="secondary">
                  <GiCutDiamond />
                </IonText>
              </h6>
            </IonText>

            {homeData && (
              <IonSlides options={slideOpts} pager>
                {homeData.HomeSliderImg.map((img: any) => (
                  <IonSlide key={img.url}>
                    <img src={img.url} />
                  </IonSlide>
                ))}
              </IonSlides>
            )}
            <span className="swiper-pagination"></span>

            <IonGrid>
              <IonRow>
                <IonCol class="ion-text-center">
                  <IonText>
                    <p>
                      {" "}
                      <IonText color="primary">
                        <BsStars />
                      </IonText>{" "}
                      La final du concours se fera au Gala{" "}
                      <IonText color="primary">
                        <BsStars />
                      </IonText>
                    </p>
                  </IonText>

                  <IonButton
                    id="homereservationgala1"
                    color="secondary"
                    style={{ align: "center" }}
                  >
                    Reserver ma place pour le Gala
                  </IonButton>
                  <ReservationGala trigger="homereservationgala1" />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonText>
              <h5 style={{ paddingLeft: "10px", marginBottom: "0px" }}>
                Participantes
              </h5>
            </IonText>

            {votesparticipantes && (
              <IonSlides options={slideOpts2}>
                {votesparticipantes.map((participante: any) => (
                  <IonSlide key={participante.id}>
                    <IonCard id={participante?.id}>
                      <IonItem>
                        <IonAvatar slot="start">
                          <img
                            src={participante?.img?.formats?.thumbnail?.url}
                          />
                        </IonAvatar>
                        <IonLabel>
                          <h2>{participante?.name}</h2>
                          <p>Miss {participante?.missId}</p>
                        </IonLabel>
                      </IonItem>

                      <img src={participante?.img?.formats?.medium?.url} />

                      <IonRow>
                        <IonCol>
                          <IonButton fill="clear">
                            <IonIcon ios={eyeOutline} />
                            <div>&nbsp;open</div>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      <CandidateDetails
                        trigger={participante?.id}
                        participante={participante}
                      />
                    </IonCard>
                  </IonSlide>
                ))}
              </IonSlides>
            )}

            <IonGrid style={{ marginTop: "-15px", marginBottom: "-15px" }}>
              <IonRow>
                <IonCol class="ion-text-center">
                  <IonText>
                    <p style={{ marginBottom: "5px" }}>
                      Deviens la future Miss Diamond Africa et gagnes{" "}
                      <b>5000€</b>
                    </p>
                  </IonText>
                  <IonButton
                    id="homecontactcandidate1"
                    color="primary"
                    style={{ align: "center" }}
                  >
                    S'enregistrer candidate
                  </IonButton>
                  <ContactCandidates trigger="homecontactcandidate1" />
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonText>
              <h5 style={{ paddingLeft: "10px", marginBottom: 0 }}>Services</h5>
            </IonText>
            <IonSlides options={slideOpts2}>
              {services.map((service) => (
                <IonSlide key={service.nom}>
                  <IonCard
                    color={service.color}
                    style={{ width: "100%" }}
                    href={service.link}
                  >
                    <IonCardContent>
                      <IonIcon icon={service.icon} size="large" /> <br />
                      <IonText>{service.nom}</IonText>
                    </IonCardContent>
                  </IonCard>
                </IonSlide>
              ))}
            </IonSlides>
            <IonText>
              <h5 style={{ paddingLeft: "10px", marginBottom: "0px" }}>
                Awards
              </h5>
            </IonText>
            {awardsparticipants && (
              <IonSlides options={slideOpts2}>
                {awardsparticipants.map((participant: any) => (
                  <IonSlide key={participant.id}>
                    <IonCard id={participant.id}>
                      <IonItem>
                        <IonAvatar slot="start">
                          <img
                            style={{ height: "70px", width: "70px" }}
                            src={participant?.img?.formats?.thumbnail?.url}
                          />
                        </IonAvatar>
                        <IonLabel>
                          <h2>{participant.name}</h2>
                          <p>{participant.categorie}</p>
                        </IonLabel>
                      </IonItem>
                      <img
                        style={{ height: "190px" }}
                        src={participant?.img?.formats?.medium?.url}
                      />
                      <IonRow>
                        <IonCol>
                          <IonButton fill="clear">
                            <IonIcon ios={eyeOutline} />
                            <div>&nbsp;open</div>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      <AwardsDetails
                        participant={participant}
                        trigger={participant.id}
                      />
                    </IonCard>
                  </IonSlide>
                ))}
              </IonSlides>
            )}

            <IonText>
              <h5 style={{ paddingLeft: "10px" }}>Collaborateurs</h5>
            </IonText>
            {sponsors && (
              <IonSlides options={slideOpts3}>
                {sponsors.map((sponsor: any) => (
                  <IonSlide key={sponsor.id}>
                    <IonAvatar id={sponsor.id} style={{ width: "100%" }}>
                      <img
                        width="100%"
                        src={sponsor.logo[0].formats?.thumbnail?.url}
                      />
                    </IonAvatar>
                    <SponsorsDetails sponsor={sponsor} trigger={sponsor.id} />
                  </IonSlide>
                ))}
              </IonSlides>
            )}

            <IonText>
              <IonNavLink
                routerDirection="forward"
                component={() => <ContactSponsors />}
              >
                <IonButton fill="clear" color="tertiary">
                  Devenir collaborateur <IonIcon icon={arrowForwardOutline} />
                </IonButton>
              </IonNavLink>
            </IonText>
            <IonText>
              <h5 style={{ paddingLeft: "10px" }}>Contact</h5>
            </IonText>
            <IonGrid>
              <IonRow>
                {contacts.map((contact, index) => (
                  <IonCol
                    key={contact.nom}
                    offset={index % 2 == 0 ? "0" : "1"}
                    size="5"
                    style={{ marginTop: "-20px" }}
                  >
                    <IonCard
                      id={contact.id}
                      color={contact.color}
                      style={{ width: "100%" }}
                    >
                      <IonCardContent>
                        <IonIcon icon={contact.icon} size="large" /> <br />
                        {contact.nom}
                      </IonCardContent>
                      {contact.component}
                    </IonCard>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AcceuilHome;
