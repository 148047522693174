import { Redirect, Route } from 'react-router-dom';
import { getPlatforms, IonApp, IonBadge, IonIcon, IonLabel, IonNav, IonRouterOutlet, IonSplitPane, IonTabBar, IonTabButton, IonTabs, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import ViewMessage from './pages/ViewMessage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { calendar, informationCircle, map, personCircle } from 'ionicons/icons';
import  Menu  from './components/Menu';
import { Cards } from './components/Cards';
import AcceuilHome from './pages/acceuil/AcceuilHome';
import { createContext, useEffect, useState } from 'react';
import {createStore, set} from './data/storage'
import VoteMiss from './pages/services/VoteMiss';
import VoteAward from './pages/services/VoteAward';
import Catalogue from './pages/acceuil/Catalogue';
import DiamondHelp from './pages/acceuil/DiamondHelp';
import ModelManag from './pages/acceuil/ModelManag';
import PhotoGallery from './pages/acceuil/PhotoGallery';
import KapyGenius from './pages/acceuil/KapyGenius';
import { checkorigin } from './origincheck';
import { auth } from './data/fireConfig';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import initiaPaypalOptions from './data/payPalConfig';
import Account from './pages/acceuil/Account';

setupIonicReact();
export const UserContext = createContext(null)

const App= ({callback} : any) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkorigin();
    createStore("__dbstore");
    var subs = auth.onAuthStateChanged((user) => {
      setUser(user as any);
    })
    return () => { subs()}
  }, [])
  
  return (
  <IonApp ref={callback}>
    <UserContext.Provider value={user}>
    <PayPalScriptProvider options={initiaPaypalOptions} >
    <IonReactRouter>
      
        <Menu/>

        <IonRouterOutlet id='main'>
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" exact={true}>
              <AcceuilHome />
            </Route>
            <Route path="/catalogue">
              <Catalogue />
            </Route>
            <Route path="/diamondhelp">
              <DiamondHelp />
            </Route>
            <Route path="/modelmanag">
              <ModelManag />
            </Route>
            <Route path="/photogallery">
              <PhotoGallery />
            </Route>
            <Route path="/kapygenius">
              <KapyGenius />
            </Route>
            <Route path="/votemiss">
              <VoteMiss />
            </Route>
            <Route path="/voteaward">
              <VoteAward/>
            </Route> 
            <Route path="/account" exact={true}>
              <Account />
            </Route>
            <Route path="/cards" exact={true}>
              <Cards />
            </Route>
            
            <Route path="/message/:id">
              <ViewMessage />
            </Route>

          </IonRouterOutlet>
      
    </IonReactRouter>
    </PayPalScriptProvider>
    </UserContext.Provider>
  </IonApp>
  )
};


export default App;
