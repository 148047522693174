import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonNav, IonNavLink, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import React, { useEffect, useState } from 'react'
import { BsStars } from 'react-icons/bs';
import ReservationGala from '../../contact/ReservationGala';
import { getMissVotes } from './VoteMissView';

function StatistiqueAward(props:any) {
    const {misses, status, votes, paidVotes} = props;
    const [data, setData] = useState([

    ])
    useEffect(() => {
     var missData = misses?.map((miss:any) => ({
        name: "miss " + miss.missId,
        vote: getMissVotes(miss.missId, votes, paidVotes)
    }))?.sort((missData1:any, missData2:any) => (missData2?.vote - missData1?.vote))
      setData(missData)
      console.log(missData);

      return () => {
      }
    }, [misses, votes, paidVotes])
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons>
                        <IonMenuButton />
                        <IonTitle>Statistique Miss </IonTitle>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <IonGrid>
                    <IonRow>
                        <IonCol class="ion-text-center">
                            <IonText>
                                <p> <IonText color='primary'><BsStars /></IonText>  La final du concours se fera au Gala <IonText color='primary'><BsStars /></IonText></p>
                            </IonText>
                            <IonNavLink routerDirection="forward" component={() => <ReservationGala />}>
                                <IonButton color="secondary" style={{ align: "center" }}>Reserver ma place pour le Gala</IonButton>
                            </IonNavLink>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <ResponsiveContainer  width="100%" height="80%" className={!status || status?.block ? "ion-hide":""}>
                    <BarChart
                        layout="vertical"
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="name"  />
                        <Tooltip />
                        <Bar dataKey="vote" fill="#4853b1" />
                    </BarChart>
                </ResponsiveContainer>
            </IonContent>

        </IonPage>
    )
}



export default StatistiqueAward