import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonPage, IonText, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { Widget } from '@uploadcare/react-widget'
import React, { useRef, useState } from 'react'
import { useFormspark } from "@formspark/use-formspark"
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';
import ModalHeader from '../../components/ModalHeader';
import useSWR from "swr";
import axiosFetcher from "../../utils/axiosFetcher"

function ReservationGala(props:any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const [submit, submitting] = useFormspark({formId: "W17OLNyi"});
  const [present] = useIonToast();
  const [nom, setNom] = useState("")
  const [email, setEmail] = useState("")
  //const [instagram, setInstagram] = useState("")
  const [tel, setTel] = useState("")
  //const [photo, setPhoto] = useState("")
  const [coupon, setCoupon] = useState("")
    const { data, isLoading } = useSWR("/gala-info", axiosFetcher);
 
  const sendInfo = async (e:React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      present({message:"your infos have been sent", duration: 2000, position:"top", icon:checkmarkCircleOutline, translucent:true})
      await submit({
                Categorie: "Gala reservation",
                Nom: nom,
                Email: email,
                //instagram: instagram,
                tel: tel,
                //profil: photo,
                coupon
      })
      setNom(""); setEmail(""); setTel("")
      
  }
  return (  
    <IonModal swipeToClose={true} ref={modal} trigger={props?.trigger}>
      <ModalHeader title="Reservation Gala" dismiss={() => modal.current?.dismiss()} />
      <IonContent>
        <IonText> <h5 className='ion-padding-start'>L'equipe MDC vous contactera pour les informations supplementaires. <br/> The MDC team will contact you for further information.</h5> </IonText>
        <form onSubmit={(e) => sendInfo(e)}>
        <IonList style={{ marginLeft: "5px" }}>
          <IonItemDivider>Informations generales</IonItemDivider>
          <IonItem>
            <IonLabel position="stacked">Nom(s) et Prenom(s)</IonLabel>
            <IonInput placeholder='your name' type='text' minlength={4} autocomplete='name'  required value={nom} onIonChange={e => setNom(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput placeholder='youremail@email.com' type="email" autocomplete='email' required value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Tel</IonLabel>
            <IonInput placeholder='+49157...' type='tel' autocomplete='tel' minlength={5} required value={tel} onIonChange={e => setTel(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Coupon Code (optional)</IonLabel>
            <IonInput placeholder='Coupon code (Ootional)' type='text' minlength={4} autocomplete='name'  required value={coupon} onIonChange={e => setCoupon(e.detail.value!)}></IonInput>
          </IonItem>
          {/* <IonItem>
            <IonLabel position="stacked">Photo profile</IonLabel>
            <Widget onChange={(file) => setPhoto(file.originalUrl || file.cdnUrl!)} publicKey='8431c83393453c254d9d' />
          </IonItem> */}
        </IonList>
        <IonButton  type="submit" expand="block">Reserver</IonButton>
        </form>
          <img src={data?.PhotoCouverture?.url}  />
          <img src={data?.Gallery && data?.Gallery[data.Gallery.length - 2].url}/>
          <img src={data?.Gallery && data?.Gallery[data.Gallery.length - 1].url} />
        {/*{[...Array(7).keys() as any].map((value:any) => <img src={"/assets/images/gala/"+(value+1)+".jpeg"}  />)}*/}
      </IonContent>
      
    </IonModal>
  )
}

export default ReservationGala