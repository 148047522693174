import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import TabContent  from './components/TabContent';
import { isPlatform } from '@ionic/react';
import { checkorigin } from './origincheck';
import { SplashScreen } from '@capacitor/splash-screen';


checkorigin();
const container = document.getElementById('root');
const root = createRoot(container!);

root.render( <App callback={() => SplashScreen.hide()} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
