import { useEffect, useState } from "react"
import axios from 'axios'
import { get, set } from "./storage"

export const useGetDataRequest = (key:string, path:string, baseurl:string = "https://admin-mdc-home-fnneu.ondigitalocean.app") => {
    const [data, setData] = useState(null)

    useEffect(() => {
        const getdata = async () => {
            var  requestdata = await get(key);
            if (requestdata) {
                setData(requestdata)
                requestdata = (await axios.get(`${baseurl}${path}`)).data;
                setData(requestdata)
                console.log("refresh with :", requestdata)
                set(key, requestdata)
            }else {
                requestdata = (await axios.get(`${baseurl}${path}`)).data;
                setData(requestdata)
                set(key, requestdata)
            }
        }
        getdata();
    }, [])

    return [data];
}