import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonModal, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react'
import React, { useRef } from 'react'
import { useParams } from 'react-router';
import ModalHeader from '../../components/ModalHeader';
import { useGetDataRequest } from '../../data/request';
import ContactASponsor from '../contact/ContactASponsor';

function SponsorsDetails(props:any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const participante = props?.sponsor
  return (
    <IonModal swipeToClose={true} ref={modal} trigger={props?.trigger}>
      <ModalHeader title={participante?.name} dismiss={() => modal.current?.dismiss()} />
    <IonContent>
      <img src={participante?.logo[0].formats?.medium?.url} />
      <IonText >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <h4>{participante?.name}</h4>
          <IonText color="primary"><small>Categorie {participante?.categorie}</small></IonText>
          <p>{participante?.description}</p>
        </div>
      </IonText>
      {/* <IonButton id={participante?.id} expand="block">Contactes nous</IonButton>
      <ContactASponsor trigger={participante?.id} /> */}
    </IonContent>
  </IonModal>
  )
}

export default SponsorsDetails