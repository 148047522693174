import React from 'react';
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge, IonRouterOutlet } from '@ionic/react';
import { calendar, personCircle, map, informationCircle, calendarOutline, homeOutline, home, clipboardOutline, clipboard, informationCircleOutline, albumsOutline, albums } from 'ionicons/icons';
import { Redirect, Route } from 'react-router';


const TabContent: React.FC = () => (
  <>


    
    <IonTabBar slot="bottom">
      

      <IonTabButton tab="speakers" href='/cards'>
        <IonIcon ios={albumsOutline} md={albums} />
        {/* <IonLabel>Speakers</IonLabel> */}
      </IonTabButton>

      <IonTabButton tab="schedule" href='/home'>
        <IonIcon ios={homeOutline} md={home}  />
        {/* <IonLabel>Schedule</IonLabel> */}
        <IonBadge>6</IonBadge>
      </IonTabButton>

      {/* <IonTabButton tab="map" href='/message/2'>
        <IonIcon icon={map} />
        <IonLabel>Map</IonLabel>
      </IonTabButton> */}

      <IonTabButton tab="about" href='/message/3'>
        <IonIcon ios={clipboardOutline} md={clipboard}/>
        {/* <IonLabel>Contact</IonLabel> */}
      </IonTabButton>
    </IonTabBar>
    </>
 
);
export default TabContent