import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonModal, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react'
import React, { useRef } from 'react'
import dateFormat from "dateformat"
import ReactMarkdown from 'react-markdown'
import ModalHeader from '../../components/ModalHeader'

function ArticleDetails(props:any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const article = props?.article
  return (
    <IonModal swipeToClose={true} ref={modal} trigger={props?.trigger}>
      <ModalHeader title={article?.titre} dismiss={() => modal.current?.dismiss()} />
    <IonContent>
      <img src={article?.image?.formats?.medium?.url || article?.image?.url} />
      <IonText >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <h4>{article?.titre}</h4>
          <IonText color="primary"><small>{ dateFormat(article?.createdAt)}</small></IonText>
          <p>
            <ReactMarkdown children={article?.test} />
          </p>
        </div>
      </IonText>
    </IonContent>
  </IonModal>
  )
}

export default ArticleDetails