import { IonBackButton, IonBadge, IonButtons, IonHeader, IonIcon, IonLabel, IonMenuButton, IonNav, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { apps, calendar, chatboxOutline, chatbubbleOutline, heartOutline, informationCircle, map, personCircle, statsChartOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router'
import { db } from '../../data/fireConfig'
import { useGetDataRequest } from '../../data/request'
import Contact from './vote/Contact'
import StatistiqueMiss from './vote/StatistiqueMiss'
import VoteMissView from './vote/VoteMissView'


function VoteMiss() {
  const [votesparticipantes]: any[] = useGetDataRequest("voteparticipantes", "/voteparticipantes")
  const [status, setStatus] = useState({after:false, block:false, during:false, pause:false, vor:false})
  const [userChoice, setUserChoice] = useState<any[]>([])
  const [paidChoices, setPaidChoices] = useState<any[]>([])

  useEffect(() => {
    var unsubscribe:any[] = []
    unsubscribe.push(db.collection("status").limit(1)
      .onSnapshot((snap)=>{
        snap.forEach((data)=>{
          setStatus(data.data() as any);
        })
      }))

      unsubscribe.push(db.collection("userchoice")
      .onSnapshot((snap)=>{
        var userchoiceSnap: any[] = []
        snap.forEach((data)=>{
          userchoiceSnap.push({id: data.id, ...data.data() as any});
        })
        //console.log(userchoiceSnap)
        setUserChoice(userchoiceSnap)
      }))

      unsubscribe.push(db.collection("paidVote")
      .onSnapshot((snap)=>{
        var paidVoteSnap: any[] = []
        snap.forEach((data)=>{
          paidVoteSnap.push({id: data.id, ...data.data() as any});
        })
        //console.log(paidVoteSnap)
        setPaidChoices(paidVoteSnap)
      }))

    return () => {
      unsubscribe.forEach(unsub => unsub())
    }
  }, [])
  
  return (
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/votemiss/vote">
              <VoteMissView misses={votesparticipantes} status={status}  votes={userChoice} paidVotes={paidChoices} />
            </Route>
            <Route exact path="/votemiss/stat">
            <StatistiqueMiss misses={votesparticipantes} status={status}  votes={userChoice} paidVotes={paidChoices}/>
            </Route>
            <Route exact path="/votemiss/chat" >
              <Contact  />
            </Route>
           <Redirect exact path='/votemiss' to='/votemiss/vote' />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="tab1" href="/votemiss/vote">
              <IonIcon icon={heartOutline} />
            </IonTabButton>

            <IonTabButton tab="tab2" href="/votemiss/stat">
              <IonIcon icon={statsChartOutline} />
            </IonTabButton>

            <IonTabButton tab="tab3" href="/votemiss/chat">
              <IonIcon icon={chatboxOutline} />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
  )
}



export default VoteMiss