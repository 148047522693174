import { IonButton, IonContent, IonIcon, IonPage } from '@ionic/react'
import { personOutline, powerOutline } from 'ionicons/icons';
import React, { useContext } from 'react'
import { UserContext } from '../../App';
import Header from '../../components/Header'
import Login from '../../components/Login'
import { auth } from '../../data/fireConfig';

function Account() {
    const user = useContext<any>(UserContext as any);
  return (
    <IonPage>
        <Header title="Mon compte" />
        <IonContent >
             <IonButton style={{marginTop:"40%"}} expand="block" id='loginaccount' className={user?"ion-hide":""}> <IonIcon icon={personOutline} />&nbsp;&nbsp;Connect</IonButton>
            <Login trigger='loginaccount' />
            <div className={!user?"ion-hide":""}>
                <IonButton style={{marginTop:"50%"}} onClick={() => auth.signOut()} color="danger" expand="block"> <IonIcon icon={powerOutline} />&nbsp;&nbsp; Disconnect</IonButton>
            </div>
        </IonContent>
    </IonPage>
  )
}

export default Account