import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react'
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import ContactKapygenius from '../contact/ContactKapygenius';

function KapyGenius() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonMenuButton />
            <IonTitle>KapyGenius </IonTitle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{backgroundColor:"var(--ion-color-primary)", height:"30%", borderBottomRightRadius:"50%", borderBottomLeftRadius:"50%"}}>
        </div>
        <img src='/assets/images/kapygenius/kapygenius-white.jpeg' style={{width:"40%", borderRadius:"50%", marginLeft:"30%", marginTop:"-20%"}}/>
        <IonText className='ion-text-center'>
          <h3>KapyGenius</h3>
          <p><IonText color="medium">Website/Mobile App Developer</IonText></p>
          <p>Hey KapyGenius build modern, beautifull and professional Websites and Mobile Apps for your bussiness.</p>
          <p>
            <IonButton shape="round" id='contactkapygenius'>Contact Us</IonButton>
            <ContactKapygenius trigger='contactkapygenius' />
          </p>
        </IonText>
        <IonText className='ion-padding-start'>
          <p className='ion-padding-start'>Our Portfolio</p>
        </IonText>
        <div>
        <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              >
                {[...Array(10).keys() as any].map((gal: any, index:number) => <div key={index} style={{ width:"40%", display:"inline-block", marginRight:"2%", marginLeft:"5%"}} data-src={ "/assets/images/kapygenius/portfolio/mockup-" + (gal+1) + ".jpg" }>
                  <a href={ "/assets/images/kapygenius/portfolio/mockup-" + (gal+1) + ".jpg" }>
                    <img alt="By KapyGenius" src={ "/assets/images/kapygenius/portfolio/mockup-" + (gal+1) + ".jpg" } />
                  </a>
                  </div>
                )}
              </LightGallery>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default KapyGenius