import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonModal, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react'
import React, { useRef } from 'react'
import ModalHeader from '../../components/ModalHeader'

function AwardsDetails(props:any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const participante = props?.participant
  return (
    <IonModal swipeToClose={true} ref={modal} trigger={props?.trigger}>
      <ModalHeader title={participante?.name} dismiss={() => modal.current?.dismiss()} />
    <IonContent>
      <img src={participante?.img?.formats?.medium?.url} />
      <IonText >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <h4>{participante?.name}</h4>
          <IonText color="primary"><small>Categorie {participante?.categorie}</small></IonText>
          <p>{participante?.description}</p>
        </div>
      </IonText>
      <IonButton expand="block">Votes pour moi</IonButton>
    </IonContent>
  </IonModal>
  )
}

export default AwardsDetails